<template>
  <div>
      <b-row v-for="(item, index) of getElements()" :key="index">
        <b-col cols="10">
          <InputGroup
            class="Input Input-collection"
            :id="`answer_${index}`"
            :type="type"
            :placeholder="placeholder"
            :value="item"
            @input.native="(e) => onChange(e, index)"
            :data-position="index"
            :state="isValid"
            :autocomplete="autocomplete"
          />
        </b-col>
        <b-col>
          <img class="deleteInput" @click="deleteInput(index)"
               src="@/assets/actions/close-red.svg" />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <PlusButton @handleClick="addElement"/>
        </b-col>
      </b-row>
  </div>
</template>

<script>
import PlusButton from '@/components/atoms/Buttons/PlusButton/PlusButton.vue';
import InputGroup from '@/components/atoms/Input/InputGroup.vue';

export default {
  components: { InputGroup, PlusButton },
  props: {
    id: String,
    value: [String, Number],
    elements: {
      type: Array,
      default: () => {},
    },
    type: {
      type: String,
      default: 'text',
    },
    placeholder: String,
    isValid: {
      type: Boolean,
      default: null,
    },
    autocomplete: {
      type: String,
      default: 'autocomplete',
    },
  },
  data() {
    return {
      text: this.value,
      elementsList: this.elements,
    };
  },
  watch: {
    value(newValue) {
      this.text = newValue;
    },
  },
  methods: {
    deleteInput(index) {
      this.elementsList.splice(index, 1);
      this.$emit('handlerElementAdded', this.elementsList);
    },
    getElements() {
      return this.elements;
    },
    addElement() {
      this.elementsList.push('');
      this.$emit('handlerElementAdded', this.elementsList);
    },
    onChange(e, index) {
      this.text = e.target.value;
      const data = {
        index,
        text: e.target.value,
      };
      this.$emit('handlerInputChange', data);
    },
    handleLogin() {
      this.$emit('handleLogin');
    },
  },
  created() {
  },
};
</script>
