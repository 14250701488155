<template>
  <div class="SideForm">
    <p class="SideForm__title">{{ title }}</p>
    <div class="SideForm__content">
      <b-row>
        <b-col class="col-12">
          <InputGroup
            id="surveyTypeFormName"
            ref="surveyTypeFormName"
            :label="$i18n.t('lb_survey_type_name')"
            :placeholder="$i18n.t('lb_type_something')"
            @handlerInputChange="handleChangeName"
            :isValid="this.validationState.name"
            error="No puede estar vacio"
            :value="this.surveyTypeData.name"
          />
          <br>

        </b-col>
<!--        <br>-->
<!--        <b-col class="col-12">-->
<!--          <SelectGroup-->
<!--            id="defaultLanguage"-->
<!--            :label="$i18n.t('lb_language')"-->
<!--            :selectedOption="this.surveyTypeData.language"-->
<!--            :options="languageOptions"-->
<!--            @handlerSelect="handleChangeLanguage"-->
<!--          />-->
<!--        </b-col>-->
        <br>
        <b-col class="col-12" v-if="this.surveyTypeData.name">
          <div class="sp__v"/>
          <b-tabs>
            <b-tab :title="$i18n.t('lb_survey_types_partials_types')">
              <SurveyTypePartialTypeTable
                v-if="hasSurveyTypePartialsType"
                :items="this.surveyTypeData.surveyTypesPartialsTypes"
                @handleEdit="handleShowEditSurveyTypePartialType"
                @handleDelete="handleShowDeleteSurveyTypePartialTypeModal"
                :canEdit="true"
                :canDelete="true"
              />
              <div class="sp__v" />
              <b-button variant="primary" size="sm"
                        @click.prevent="showCreateSurveyTypePartialTypeModal">
                {{ `+ ${$i18n.t('btn_new')}` }}
              </b-button>
              <div class="sp__v__1s5x" />
            </b-tab>
          </b-tabs>
        </b-col>
      </b-row>
    </div>

    <SurveyTypePartialTypeModal
      :id="`${id}-surveyTypeCreateSurveyTypePartialType`"
      ref="surveyTypeCreateSurveyTypePartialType"
      :isLoading="isSurveyTypePartialTypeLoaing"
      @handleUploadSurveyTypePartialType="handleCreateSurveyTypePartialType"
      isEditable
      :surveyType="getSurveyType"
    />
    <SurveyTypePartialTypeModal
      :id="`${id}-surveyTypeUpdateSurveyTypePartialType`"
      ref="surveyTypeUpdateSurveyTypePartialType"
      :isLoading="isSurveyTypePartialTypeLoaing"
      @handleUploadSurveyTypePartialType="handleUploadSurveyTypePartialType"
      isEditable
      :surveyType="getSurveyType"
    />
    <DeleteConfirmModal
      :id="`${id}-surveyTypeFormDeleteSurveyTypePartialType`"
      ref="surveyTypeFormDeleteSurveyTypePartialType"
      @handleDelete="handleDeleteSurveyTypePartialType"
      :title="$i18n.t('lb_confirm_title')"
      :text="$i18n.t('lb_confirm_text')"
    />
  </div>
</template>

<script>
import { uuid } from 'vue-uuid';
import InputGroup from '@/components/atoms/Input/InputGroup.vue';
import { getValidationState, isValidForm } from '@/helpers/ValidationHelper';
import defaultSurveyType from '@/views/Survey/Survey_templates/defaultSurveyType';
import SurveyTypePartialTypeTable from '@/components/molecules/Tables/SurveyTypePartialTypeTable/SurveyTypePartialTypeTable.vue';
import SurveyTypePartialTypeModal from '@/components/molecules/Modal/SurveyTypePartialTypeModal.vue';
import DeleteConfirmModal from '@/components/molecules/Modal/DeleteConfirmModal.vue';
// import SelectGroup from '@/components/atoms/Select/SelectGroup.vue';
import { LANGUAGE_SELECTION_OPTIONS } from '@/helpers/ComponentHelper';

export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    surveyType: {
      type: Object,
      default: () => defaultSurveyType,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      languageOptions: LANGUAGE_SELECTION_OPTIONS,
      isSurveyTypePartialTypeLoaing: false,
      surveyTypeData: {
        id: this.surveyType?.id,
        name: this.surveyType?.name ?? '',
        createdByData: this.surveyType?.createdByData,
        createdAt: this.surveyType?.createdAt,
        partialsType: this.surveyType?.partialsType,
        language: this.surveyType?.language,
        surveyTypesPartialsTypes: this.surveyType?.surveyTypesPartialsTypes,
      },
      validationState: {
        name: null,
      },
    };
  },
  methods: {
    resetSurveyTypeData() {
      this.surveyType.id = null;
      this.surveyType.name = null;
      this.surveyType.createdByData = null;
      this.surveyType.language = null;
      this.surveyType.createdAt = null;
      this.surveyType.partialsType = null;
      this.surveyType.surveyTypesPartialsTypes = [];
      this.surveyTypeData.surveyTypesPartialsTypes = [];
    },

    resetValidationSurveyTypeData() {
      this.validationState.name = null;
    },
    updateSurveyTypePartialType(surveyTypePartialType) {
      for (let i = 0; this.surveyTypeData.surveyTypesPartialsTypes.length; i += 1) {
        if (this.surveyTypeData.surveyTypesPartialsTypes[i].id === surveyTypePartialType.id) {
          const newSurveyTypePartialType = {
            id: surveyTypePartialType.id,
            surveyTypeId: surveyTypePartialType.surveyTypeId,
            partialsType: surveyTypePartialType.partialsType,
            title: surveyTypePartialType.title,
            description: surveyTypePartialType.description,
            help: surveyTypePartialType.help,
            name: surveyTypePartialType.name,
            placeholder: surveyTypePartialType.placeholder,
            isRequired: surveyTypePartialType.isRequired,
            data: surveyTypePartialType.data,
          };

          this.surveyTypeData.surveyTypesPartialsTypes.splice(i, 1);
          this.surveyTypeData.surveyTypesPartialsTypes.splice(i, 0, newSurveyTypePartialType);
          break;
        }
      }

      this.closeUpdateSurveyTypePartialTypeModal();
    },
    closeUpdateSurveyTypePartialTypeModal() {
      this.$bvModal.hide(`${this.id}-surveyTypeUpdateSurveyTypePartialType`);
    },
    updateSurveyTypePartialTypeRequest(surveyTypePartialType) {
      this.isSurveyTypePartialTypeLoaing = true;

      const requestData = {
        id: surveyTypePartialType.id,
        body: surveyTypePartialType,
      };

      this.$store.dispatch('surveyTypePartialsType/updateSurveyTypePartialsType',
        requestData)
        .then(() => {
          this.updateSurveyTypePartialType(surveyTypePartialType);
          this.isSurveyTypePartialTypeLoaing = false;
        })
        .catch(() => {
          this.isSurveyTypePartialTypeLoaing = false;
        });
    },
    handleUploadSurveyTypePartialType(surveyTypePartialType) {
      if (this.isEdit) {
        this.updateSurveyTypePartialTypeRequest(surveyTypePartialType);
      } else {
        this.updateSurveyTypePartialType(surveyTypePartialType);
      }
    },
    closeCreateNoteModal() {
      this.$bvModal.hide(`${this.id}-surveyTypeCreateSurveyTypePartialType`);
    },
    addNewSurveyTypePartialType(surveyTypePartialsType) {
      this.surveyTypeData.surveyTypesPartialsTypes.unshift(surveyTypePartialsType);

      this.closeCreateNoteModal();
    },
    createSurveyTypePartialType(surveyTypePartialType) {
      const surveyTypePartialsTypeData = {
        id: surveyTypePartialType.id,
        title: surveyTypePartialType.title,
        description: surveyTypePartialType.description,
        help: surveyTypePartialType.help,
        name: surveyTypePartialType.name,
        placeholder: surveyTypePartialType.placeholder,
        isRequired: surveyTypePartialType.isRequired,
        data: surveyTypePartialType.data,
        surveyTypeId: surveyTypePartialType.surveyTypeId,
        partialsTypeId: surveyTypePartialType.partialsType.id,
      };

      this.isSurveyTypePartialTypeLoaing = true;

      this.$store.dispatch('surveyTypePartialsType/createSurveyTypePartialsType',
        surveyTypePartialsTypeData)
        .then(() => {
          this.addNewSurveyTypePartialType(surveyTypePartialType);
          this.isSurveyTypePartialTypeLoaing = false;
        })
        .catch(() => {
          this.isSurveyTypePartialTypeLoaing = false;
        });
    },
    handleCreateSurveyTypePartialType(surveyTypePartialType) {
      if (this.isEdit) {
        this.createSurveyTypePartialType(surveyTypePartialType);
      } else {
        this.addNewSurveyTypePartialType(surveyTypePartialType);
      }
    },
    handleShowDeleteSurveyTypePartialTypeModal(surveyTypePartialTypeId) {
      this.$refs.surveyTypeFormDeleteSurveyTypePartialType.setUuid(surveyTypePartialTypeId);
      this.$bvModal.show(`${this.id}-surveyTypeFormDeleteSurveyTypePartialType`);
    },
    deleteSurveyTypePartialType(surveyTypePartialTypeId) {
      this.$bvModal.hide(`${this.id}-surveyTypeFormDeleteSurveyTypePartialType`);

      for (let i = 0; this.surveyTypeData.surveyTypesPartialsTypes.length; i += 1) {
        if (this.surveyTypeData.surveyTypesPartialsTypes[i].id === surveyTypePartialTypeId) {
          this.surveyTypeData.surveyTypesPartialsTypes.splice(i, 1);
          break;
        }
      }
    },
    deleteSurveyTypePartialTypeRequest(surveyTypePartialTypeId) {
      this.isNoteLoaing = true;

      this.$store.dispatch('surveyTypePartialsType/deleteSurveyTypePartialsType',
        surveyTypePartialTypeId)
        .then(() => {
          this.deleteSurveyTypePartialType(surveyTypePartialTypeId);
          this.isNoteLoaing = false;
        })
        .catch(() => {
          this.isNoteLoaing = false;
        });
    },
    handleDeleteSurveyTypePartialType(surveyTypePartialTypeId) {
      if (this.isEdit) {
        this.deleteSurveyTypePartialTypeRequest(surveyTypePartialTypeId);
      } else {
        this.deleteSurveyTypePartialType(surveyTypePartialTypeId);
      }
    },
    handleShowEditSurveyTypePartialType(surveyTypePartialType) {
      this.$refs.surveyTypeUpdateSurveyTypePartialType
        .setSurveyTypePartialType(surveyTypePartialType);
      this.$bvModal.show(`${this.id}-surveyTypeUpdateSurveyTypePartialType`);
    },
    showCreateSurveyTypePartialTypeModal() {
      const newSurveyTypePartialType = {
        id: uuid.v4(),
      };

      this.$refs.surveyTypeCreateSurveyTypePartialType
        .setSurveyTypePartialType(newSurveyTypePartialType);
      this.$bvModal.show(`${this.id}-surveyTypeCreateSurveyTypePartialType`);
    },
    handleChangeName(value) {
      this.surveyTypeData.name = value;
    },
    handleChangeLanguage(value) {
      this.surveyTypeData.language = value;
    },
    handleOk() {
      const idValidationState = getValidationState(
        this.surveyTypeData.id,
      );
      const nameValidationState = getValidationState(
        this.surveyTypeData.name,
      );
      const createdByValidationState = getValidationState(
        this.surveyTypeData.createdByData,
      );
      const createdAtValidationState = getValidationState(
        this.surveyTypeData.createdAt,
      );
      const surveyTypesPartialsTypesValidationState = getValidationState(
        this.surveyTypeData.surveyTypesPartialsTypes,
      );

      if (isValidForm([
        idValidationState,
        nameValidationState,
        createdByValidationState,
        createdAtValidationState,
        surveyTypesPartialsTypesValidationState,
      ])) {
        this.surveyTypeData.id = uuid.v4();
        this.sendOk();
      } else {
        this.validationState.id = idValidationState;
        this.validationState.name = nameValidationState;
        this.validationState.createdByData = createdByValidationState;
        this.validationState.createdAt = createdAtValidationState;
        this.validationState.surveyTypesPartialsTypes = surveyTypesPartialsTypesValidationState;
      }
    },
    sendOk() {
      this.$emit('handleAccept', this.surveyTypeData);
    },
    resetValidations() {
      this.validationState.name = null;
      this.validationState.createdAt = null;
    },
    getData() {
      return this.surveyTypeData;
    },
  },
  computed: {
    hasSurveyTypePartialsType() {
      return this.surveyTypeData.surveyTypesPartialsTypes !== undefined
        && this.surveyTypeData.surveyTypesPartialsTypes.length > 0;
    },
    getSurveyType() {
      return this.surveyType;
    },
  },
  watch: {
    surveyType(newValue) {
      this.surveyTypeData.id = newValue.id;
      this.surveyTypeData.name = newValue.name;
      this.surveyTypeData.language = newValue.language;
      this.surveyTypeData.createdByData = newValue.createdByData;
      this.surveyTypeData.createdAt = newValue.createdAt;
      this.surveyTypeData.surveyTypesPartialsTypes = newValue.surveyTypesPartialsTypes;
    },
  },
  components: {
    // SelectGroup,
    SurveyTypePartialTypeModal,
    InputGroup,
    SurveyTypePartialTypeTable,
    DeleteConfirmModal,
  },
};
</script>

<style lang="sass" scoped>
@import './SideForm.sass'
</style>
