<template>
      <div class="position-relative">
        <div class="buttonAbsolute">
          <b-button
          @click.prevent="showCreateModal"
          variant="primary"
          size="sm"
        >
          {{ `+ ${$i18n.t('btn_survey_new')}` }}
        </b-button>
          <b-button
            @click.prevent="showCreateView"
            variant="primary"
            size="sm"
          >
            {{ `+ ${$i18n.t('btn_survey_type_new')}` }}
          </b-button>
        </div>
        <div class="sp__v__2x"/>
        <TableGroup
          id="survey"
          :items="surveyTypeList"
          :pagination="pagination"
          :isLoading="isTableLoading"
          :showFilterButton="false"
          :searchText="searchText"
          @handleSearch="handleSearchByInput"
          @handleSearchByFilter="handleSearchByFilter"
          @handlePaginate="handleFilterSearch"
          @handleCleanSearch="handleCleanSearch"
        >
          <SurveyTypeTable
            ref="surveyTypeTable"
            :surveyItems="surveyTypeList"
            :showActions="true"
            :isFormShowed="showDetail"
            @showDeleteModal="showDeleteModal"
            @handleSelectRow="handleSelectRow"
            @showUpdateForm="showUpdateForm"
            @showSendSurvey="showSendSurvey"
            canEdit
            :canCopy="false"
            :canDelete="true"
            :canSend="true"
          />
        </TableGroup>
        <CreateSurveyType
          id="create-survey-type"
          :title="$i18n.t('lb_new_survey_type')"
          :show="showCreateSurveyType"
          @addSurveyType="addSurveyType"
          @handleCancel="closeCreateModal"
        />
        <CreateSurvey
          v-if="showCreateSurvey"
          :patientsList="patientsList"
          :surveyType="currentSurvey"
          @addSurvey="addSurvey"
          @handleCancel="closeCreateSurveyModal"
        />
        <UpdateSurveyType
          id="update-survey-type"
          ref="update-survey-type"
          :title="$i18n.t('lb_update_survey_type')"
          :show="showCreateSurveyType"
          :surveyType="surveyType"
          @updateSurveyType="updateSurveyType"
        />
        <DeleteSurveyType
          @deleteSurveyType="deleteSurveyType"
          ref="deleteSurveyType"
        />
        <ImportPatientModal
          id="requestFormImportPatientModal"
          :isCsvLoading="isCsvLoading"
          @handleUploadFile="handleUploadImportPatient"
        />
      </div>
</template>

<script>
import { mapGetters } from 'vuex';
import TableGroup from '@/components/molecules/Tables/TableGroup/TableGroup.vue';
import SurveyTypeTable from '@/components/molecules/Tables/SurveyTypeTable/SurveyTypeTable.vue';
import CreateSurveyType from '@/components/organisms/SurveyType/CreateSurveyType.vue';
import DeleteSurveyType from '@/components/organisms/SurveyType/DeleteSurveyType.vue';
import { getFiltersGroup, getOrdenation } from '@/helpers/ApiHelper';
import SurveyTypeModel from '@/domain/surveyType/SurveyTypeModel';
import SurveyModel from '@/domain/survey/SurveyModel';
import { getCurrentDate, uploadCsv } from '@/helpers/ComponentHelper';
import UpdateSurveyType from '@/components/organisms/SurveyType/UpdateSurveyType.vue';
import defaultSurveyType from '@/views/Survey/Survey_templates/defaultSurveyType';

export default {
  data() {
    return {
      surveyList: [],
      surveyTypeList: [],
      currentSurvey: null,
      isCsvLoading: false,
      patientsList: [],
      showCreateSurvey: false,
      showCreateSurveyType: false,
      pagination: {},
      surveyType: defaultSurveyType, // defaultRequest
      showDetail: false,
      searchText: null,
      sort: {
        name: 'createdAt',
        type: 'desc',
      },
      filters: {
        name: null,
        surveyType: null,
      },
      isTableLoading: false,
    };
  },
  mounted() {
    if (this.isUserLoading === false) {
      this.handleSearch();
    }
  },
  watch: {
    isUserLoading(newValue) {
      if (newValue === false) {
        this.handleSearch();
      }
    },
  },
  methods: {
    updateSurveyType(data) {
      for (let i = 0; this.surveyTypeList.length; i += 1) {
        if (this.surveyTypeList[i].id === data.id) {
          const newSurveyType = {
            id: data.id,
            name: data.name,
            language: data.language,
            templatePath: data.templatePath,
            createdAt: data.createdAt,
            createdByData: this.user,
            surveyTypesPartialsTypes: data.surveyTypesPartialsTypes,
          };
          this.surveyTypeList.splice(i, 1);
          this.surveyTypeList.splice(i, 0, newSurveyType);
          break;
        }
      }

      this.$root.$emit('bv::toggle::collapse', 'update-survey-type-sidebar');

      this.setSurveyType(data);
    },
    deleteSurveyType(id) {
      this.$bvModal.hide('delete-survey-type');

      if (this.showDetail) {
        this.hideDetailView();
      }

      for (let i = 0; this.surveyTypeList.length; i += 1) {
        if (this.surveyTypeList[i].id === id) {
          this.surveyTypeList.splice(i, 1);
          this.handleSearch(this.pagination.currentPage);
          break;
        }
      }
    },
    showDeleteModal(data) {
      this.$refs.deleteSurveyType.setId(data.id);
      this.$bvModal.show('delete-survey-type');
    },
    showUpdateForm(data) {
      this.setSurveyType(data);

      this.$root.$emit('bv::toggle::collapse', 'update-survey-type-sidebar');
    },
    handleChangeSurveyType(value) {
      this.filters.surveyType = value;
    },
    handleCleanSearch() {
      this.filters.name = null;
      this.searchText = null;
      this.sort.name = null;
      this.sort.type = null;

      this.handleSearch();
    },
    handleSearchByFilter() {
      this.handleFilterSearch();
      this.$root.$emit('bv::toggle::collapse', 'survey-sidebar');
    },
    getFilters() {
      const filtersGroup = [];

      if (this.searchText !== null) {
        filtersGroup.push(
          `name:${this.searchText}`,
        );
      }

      return getFiltersGroup(filtersGroup, this.filters);
    },
    getRequestData(page = 1) {
      return {
        page,
        filters: this.getFilters(),
        ordenation: getOrdenation(this.sort),
        language: this.user.defaultLanguage,
      };
    },
    handleSearchByInput(text) {
      if (text !== null) {
        this.searchText = text;
        this.handleFilterSearch();
      } else {
        this.searchText = null;
      }
    },
    handleFilterSearch(page = 1) {
      this.isTableLoading = true;

      this.$store.dispatch('surveyType/filterSurveyType', this.getRequestData(page))
        .then((res) => {
          this.surveyTypeList = res.surveyTypeList;
          this.pagination = res.pagination;
          this.isTableLoading = false;
        })
        .catch(() => {
          this.isTableLoading = false;
        });
    },
    handleSearch(page = 1) {
      this.isTableLoading = true;
      const language = this.user.defaultLanguage;
      this.$store.dispatch('surveyType/getAllSurveyType', {
        page,
        itemsPerPage: 10,
        uuid: this.user.uuid,
        language,
      })
        .then((res) => {
          this.surveyTypeList = res.surveyTypeList;
          this.pagination = res.pagination;
          this.isTableLoading = false;
        })
        .catch(() => {
          this.isTableLoading = false;
        });
    },
    resetSurveyType() {
      this.surveyType.id = null;
      this.surveyType.name = null;
      this.surveyType.language = null;
      this.surveyType.createdByData = null;
      this.surveyType.createdAt = null;
      this.surveyType.partialsType = null;
      this.surveyType.surveyTypesPartialsTypes = [];
    },
    showCreateModal() {
      this.showCreateSurvey = true;
    },
    showSendSurvey(data) {
      this.currentSurvey = data;
      this.showCreateSurvey = true;
    },
    showCreateView() {
      this.showCreateSurveyType = true;
      this.$root.$emit('bv::toggle::collapse', 'create-survey-type-sidebar');
      this.resetSurveyType();
    },
    closeCreateModal() {
      this.showCreateSurveyType = false;
    },
    closeCreateSurveyModal() {
      this.patientsList = [];
      this.currentSurvey = null;
      this.showCreateSurvey = false;
    },
    closeSideBar() {
      this.$root.$emit('bv::toggle::collapse', 'update-survey-type-sidebar');
    },
    closeCreateModalAfterAction() {
      this.closeCreateModal();
      this.$root.$emit('bv::toggle::collapse', 'create-survey-type-sidebar');
    },
    closeDetail() {
      this.hideDetailView();
      this.$refs.surveyTable.clearSelected();
    },
    showDetailView() {
      this.showDetail = true;
    },
    hideDetailView() {
      this.showDetail = false;
    },
    handleSelectRow(item) {
      this.setSurveyType(item);
      if (!this.showDetail) {
        this.$root.$emit('bv::toggle::collapse', 'survey-detail-sidebar');
      }

      this.showDetailView();
    },
    setSurveyType(data) {
      this.surveyType = data;
    },
    addSurveyType(data) {
      const newSurveyType = new SurveyTypeModel({
        id: data.id,
        name: data.name,
        templatePath: data.templatePath,
        language: data.language,
        createdByData: this.user,
        createdAt: getCurrentDate(),
        updatedAt: data.updatedAt,
        deletedAt: data.deletedAt,
        surveyTypesPartialsTypes: data.surveyTypesPartialsTypes,
      });

      this.surveyTypeList.unshift(newSurveyType);

      this.incrementPagination();
      this.closeCreateModalAfterAction();
    },
    incrementPagination() {
      this.pagination.totalItems += 1;
      this.pagination.itemsPerPage += 1;
    },
    addSurvey(data) {
      data.patientsList.forEach((patient) => {
        const newSurvey = new SurveyModel({
          id: data.id,
          sendBy: this.user.uuid,
          patient,
          surveyType: data.surveyType,
          request: data.request,
          sendAt: data.sendAt,
          answeredAt: data.answeredAt,
          createdAt: data.createdAt,
          deletedAt: data.deletedAt,
        });

        this.surveyList.unshift(newSurvey);
        this.incrementPagination();
      });

      this.closeCreateSurveyModal();
    },
    handleUploadImportPatient(document) {
      let requestData = uploadCsv(document);
      requestData = {
        ...requestData,
        isExternalPatient: true,
      };

      this.isCsvLoading = true;

      this.$store.dispatch('patient/importPatients', requestData)
        .then((res) => {
          this.patientsList = res.data.collection;
          this.closeImportModal();
          this.isCsvLoading = false;
        })
        .catch(() => {
          this.isCsvLoading = false;
        });
    },
  },
  computed: {
    ...mapGetters('user', {
      user: 'getCurrentUser',
    }),
    isUserLoading() {
      return this.$store.getters['ui/getIsUserLoading'];
    },
    getDefaultLanguage() {
      return this.user.defaultLanguage;
    },
  },
  components: {
    TableGroup,
    SurveyTypeTable,
    CreateSurveyType,
    UpdateSurveyType,
    DeleteSurveyType,
    CreateSurvey: () => import('@/components/organisms/Survey/CreateSurvey.vue'),
    ImportPatientModal: () => import('@/components/molecules/Modal/ImportPatientModal.vue'),
    // SurveyDetail,
  },
};
</script>
<style lang="sass" scoped>
  @import "../Survey.sass"
</style>
