<template>
  <div class="Table">
    <b-table
      :items="items"
      :fields="colums"
      hover
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      selectable
      select-mode="single"
      @row-selected="onRowSelected"
    >
      <template #cell(name)="data">
        <Badge variant="neutral">
          {{ data.item.name }}
        </Badge>
      </template>
      <template #cell(createdAt)="data">
        <span class="text__sr text-secondary">{{ data.item.createdAt | formatDate }}</span>
      </template>
      <template #cell(createdByData)="data">
        {{ getUserName(data) }}
      </template>
      <template #cell(countSent)="data">
        <span class="text__sr text-secondary">
          {{ data.item.totalSurveys }}
        </span>
      </template>
      <template #cell(countAnswered)="data">
        <span class="text__sr text-secondary">
          {{ data.item.answered }}
        </span>
      </template>
      <template #cell(responseRate)="data">
        <span class="text__sr text-secondary">
          {{ getPercent(data) }}%
        </span>
      </template>
      <template #cell(actions)="data">
        <IconActionsGroup
          @edit="showUpdateForm"
          @delete="showDeleteModal"
          @preview="previewSurveyType"
          @send="handleSendSurvey"
          :objectData="data.item"
          :canDelete="getCanDelete(data.item)"
          :canEdit="getCanEdit(data.item)"
          :canCopy="canCopy"
          :canPreview="true"
          :canSend="canSend"
        />
      </template>
    </b-table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import IconActionsGroup from '@/components/atoms/Icon/IconActionsGroup/IconActionsGroup.vue';
import Badge from '@/components/atoms/Badge/Badge.vue';
import { getAvatarIcon } from '@/helpers/UserHelper';
import i18n from '@/plugins/vue-i18n';

export default {
  props: {
    surveyItems: {
      type: Array,
      default: () => [],
    },
    canEdit: {
      type: Boolean,
      default: true,
    },
    canDelete: {
      type: Boolean,
      default: true,
    },
    showActions: {
      type: Boolean,
      default: true,
    },
    canCopy: {
      type: Boolean,
      default: true,
    },
    canSend: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      sortBy: 'createdAt',
      sortDesc: true,
      items: this.surveyItems,
      createdByData: null,
    };
  },
  computed: {
    ...mapGetters('user', {
      user: 'getCurrentUser',
    }),
    colums() {
      return [
        {
          key: 'name',
          label: i18n.t('lb_name'),
          sortable: true,
        },
        {
          key: 'createdAt',
          label: i18n.t('lb_created'),
          sortable: true,
        },
        {
          key: 'createdByData',
          label: i18n.t('lb_created_by'),
          sortable: true,
        },
        {
          key: 'countSent',
          label: i18n.t('lb_survey_sent_count'),
          sortable: true,
        },
        {
          key: 'countAnswered',
          label: i18n.t('lb_survey_answered'),
          sortable: true,
        },
        {
          key: 'responseRate',
          label: i18n.t('lb_survey_response_rate'),
          sortable: true,
        },
        {
          key: 'actions',
          label: '',
        },
      ];
    },
  },
  methods: {
    getPercent(data) {
      const percent = (data.item.answered / data.item.totalSurveys) * 100;
      return (Number.isNaN(percent) ? 0.00 : percent).toFixed(2);
    },
    isVidasprime() {
      return this.$store.getters['user/isVidasprime'];
    },
    getCanEdit(data) {
      return (this.canEdit && this.user.id === data.createdByData.id)
        || this.isVidasprime();
    },
    getCanDelete(data) {
      return (this.canDelete && this.user.id === data.createdByData.id)
        || this.isVidasprime();
    },
    getUserName(data) {
      return data.item.createdByData.userType !== undefined ? data.item.createdByData.userType.name
        : data.item.createdByData.name;
    },
    onRowSelected(items) {
      this.$emit('handleSelectRow', items[0]);
    },

    showUpdateForm(data) {
      this.$emit('showUpdateForm', data);
    },

    showDeleteModal(data) {
      this.$emit('showDeleteModal', data);
    },
    handleSendSurvey(data) {
      this.$emit('showSendSurvey', data);
    },
    previewSurveyType(data) {
      const route = this.$router.resolve({ path: `/surveyType/${data.id}` });
      window.open(route.href);
    },
    getIcon(avatar) {
      return getAvatarIcon(avatar);
    },
  },
  watch: {
    sortBy(newValue) {
      this.$emit('handleSortAsc', newValue);
    },

    sortDesc(newValue) {
      if (newValue === true) {
        this.$emit('handleSortDesc', this.sortBy);
      } else {
        this.$emit('handleSortAsc', this.sortBy);
      }
    },
  },
  components: {
    Badge,
    IconActionsGroup,
  },
};
</script>

<style lang="sass" scoped>
@import '../Table.sass'
</style>
