<template>
  <div class="Table Table__list">
    <b-table
      thead-class="d-none"
      :items="items"
      :fields="columns"
      fixed
    >
      <template #cell(info)="data">
        <p class="text__psbb text-dark ellipsis">
          <Badge class="text-soft-dark" v-if="data.item.isRequired" variant="warning">
            Este campo es obligatorio
          </Badge>
          <Badge class="text-soft-dark" v-else variant="dark">Este campo es opcional</Badge>
          <br>
          {{ data.item.name }}
          <span class="text__pr text-soft-dark">
          </span>
        </p>

        <div class="sp__v__1s5x"/>
        <p class="text__sr text-secondary">{{ data.item.title }}</p>
        <div class="sp__v__1s5x"/>
      </template>
      <template #cell(actions)="data">
        <div class="Table__list__actions">
          <div class="sp__1s5x"/>
          <b-button
            v-if="canEdit"
            variant="outline-primary"
            @click.prevent="handleEdit(data.item)"
          >
            <inline-svg
              class="icon__primary"
              :src="require('@/assets/actions/edit.svg')"
            />
          </b-button>
          <div class="sp__1s5x"/>
          <b-button
            v-if="canDelete"
            variant="outline-primary"
            @click.prevent="handleDelete(data.item.id)"
          >
            <inline-svg
              class="icon__primary"
              :src="require('@/assets/actions/delete.svg')"
            />
          </b-button>
        </div>
      </template>
    </b-table>
  </div>
</template>

<script>

import i18n from '@/plugins/vue-i18n';
import Badge from '@/components/atoms/Badge/Badge.vue';

export default {
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    canEdit: {
      type: Boolean,
      default: false,
    },
    canDelete: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    columns() {
      return [
        { key: 'info', label: i18n.t('lb_name'), sortable: true },
        { key: 'actions', label: '' },
      ];
    },
  },
  methods: {
    handleShowDetail(note) {
      this.$emit('handleShowDetail', note);
    },

    handleEdit(surveyTypePartialType) {
      this.$emit('handleEdit', surveyTypePartialType);
    },

    handleDelete(id) {
      this.$emit('handleDelete', id);
    },
  },
  components: {
    Badge,
  },
  data() {
    return {
      sortBy: 'case',
      sortDesc: false,
    };
  },
};
</script>

<style lang="sass" scoped>
@import '../Table.sass'
</style>
