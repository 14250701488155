<template>
  <ModalAction
    :id="id"
    :title="getTitle"
    :okLabel="$i18n.t('btn_update')"
    :cancelLabel="$i18n.t('btn_cancel')"
    @handleOk="uploadSurveyTypePartialType"
    :hideFooter="isDisabled"
    :isLoading="isLoading"
  >
    <b-row>
      <b-col class="col-12">
        <SelectGroup
          :id="id"
          :label="$i18n.t('lb_patial_type')"
          :placeholder="$i18n.t('lb_select_partial_type')"
          @handlerSelect="handleChangePartialType"
          :selectedOption="getValue()"
          :isValid="this.validationState.partialsType"
          :options="getPartialsType"
        />
        <br>
        <InputGroup
          id="surveyTypePartialTypeFormName"
          :label="$i18n.t('lb_survey_type_partials_type_name')"
          :placeholder="$i18n.t('lb_type_something')"
          @handlerInputChange="handleChangeName"
          :isValid="this.validationState.name"
          error="No puede estar vacio"
          :value="this.surveyTypePartialType.name"
        />

        <br>
        <label><b>{{ $i18n.t('lb_survey_type_partials_type_title') }}</b></label>
        <Textarea
          id="surveyTypePartialTypeFormTitle"
          :label="$i18n.t('lb_survey_type_partials_type_title')"
          :placeholder="$i18n.t('lb_type_something')"
          @handlerChange="handleChangeTitle"
          :isValid="this.validationState.title"
          error="No puede estar vacio"
          :value="this.surveyTypePartialType.title"
        />

        <div v-if="showAnswers">
          <br>
          <label><b>Respuestas predefinidas</b></label>
          <InputCollection
            id="surveyTypePartialTypeFormData"
            :placeholder="$i18n.t('lb_type_something')"
            :elements="getAnswers(this.surveyTypePartialType.data)"
            @handlerElementAdded="handlerUpdateData"
            @handlerInputChange="handlerChangeData"
            error="No puede estar vacio"
          />
        </div>
        <br>
        <InputGroup
          id="surveyTypePartialTypeFormDescription"
          :label="$i18n.t('lb_survey_type_partials_type_description')"
          :placeholder="$i18n.t('lb_type_something')"
          @handlerInputChange="handleChangeDescription"
          :isValid="this.validationState.description"
          error="No puede estar vacio"
          :value="this.surveyTypePartialType.description"
        />

        <br>
        <InputGroup
          id="surveyTypePartialTypeFormHelp"
          :label="$i18n.t('lb_survey_type_partials_type_help')"
          :placeholder="$i18n.t('lb_type_something')"
          @handlerInputChange="handleChangeHelp"
          :isValid="this.validationState.help"
          error="No puede estar vacio"
          :value="this.surveyTypePartialType.help"
        />

<!--        <br>-->
<!--        <InputGroup-->
<!--          id="surveyTypePartialTypeFormPlaceholder"-->
<!--          :label="$i18n.t('lb_survey_type_partials_type_placeholder')"-->
<!--          :placeholder="$i18n.t('lb_type_something')"-->
<!--          @handlerInputChange="handleChangePlaceholder"-->
<!--          :isValid="this.validationState.placeholder"-->
<!--          error="No puede estar vacio"-->
<!--          :value="this.surveyTypePartialType.placeholder"-->
<!--        />-->

        <br>
        <Checkbox
          id="surveyTypePartialTypeFormIsRequired"
          :label="$i18n.t('lb_survey_type_partials_type_is_required')"
          :description="$i18n.t('cp_survey_type_partials_type_is_required')"
          :value="this.surveyTypePartialType.isRequired"
          :isValid="this.validationState.isRequired"
          @handleChange="handleChangeIsRequired"
          isSwitch
        />
      </b-col>
    </b-row>
  </ModalAction>
</template>

<script>
import ModalAction from '@/components/molecules/Modal/ModalAction.vue';
import InputGroup from '@/components/atoms/Input/InputGroup.vue';
import Checkbox from '@/components/atoms/Checkbox/Checkbox.vue';
import Textarea from '@/components/atoms/Textarea/Textarea.vue';
import SelectGroup from '@/components/atoms/Select/SelectGroup.vue';
import { getSelectDefault } from '@/helpers/ComponentHelper';
import InputCollection from '@/components/atoms/Input/InputCollection.vue';

export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    isEditable: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    surveyType: {
      type: Object,
      default: () => {},
    },
    validationState: {
      type: Object,
      default: () => ({
        id: null,
        partialsTypes: null,
        partialsType: null,
        title: null,
        description: null,
        help: null,
        name: null,
        placeholder: null,
        isRequired: false,
        data: {},
      }),
    },
  },
  created() {
    this.getPartialsTypeList();
  },
  data() {
    return {
      partialsTypes: [],
      showAnswers: false,
      surveyTypePartialType: {
        id: null,
        partialsTypes: null,
        partialsType: null,
        title: '',
        description: '',
        help: '',
        name: '',
        placeholder: '',
        isRequired: false,
        data: {},
      },
    };
  },
  methods: {
    getAnswers(data) {
      if (data) {
        return Object.entries(data).map((x) => x[1]);
      }
      return [];
    },
    getJson(data) {
      if (data !== undefined && typeof data !== 'object' && data.length > 0) {
        return JSON.parse(data);
      }
      return data;
    },
    getValue() {
      if (this.surveyTypePartialType.partialsType) {
        return this.surveyTypePartialType.partialsType.id;
      }
      return null;
    },
    async getPartialsTypeList() {
      await this.$store.dispatch('partialsType/getAllPartialsTypes')
        .then((res) => {
          this.partialsTypes = res;
        })
        .catch();
    },
    setSurveyTypePartialType(surveyTypePartialType) {
      this.surveyTypePartialType.id = surveyTypePartialType.id;
      this.surveyTypePartialType.partialsTypes = surveyTypePartialType.partialsTypes;
      this.surveyTypePartialType.partialsType = surveyTypePartialType.partialsType;
      this.surveyTypePartialType.name = surveyTypePartialType.name;
      this.surveyTypePartialType.title = surveyTypePartialType.title;
      this.surveyTypePartialType.description = surveyTypePartialType.description;
      this.surveyTypePartialType.help = surveyTypePartialType.help;
      this.surveyTypePartialType.placeholder = surveyTypePartialType.placeholder;
      this.surveyTypePartialType.isRequired = surveyTypePartialType.isRequired;
      this.surveyTypePartialType.data = this.getJson(surveyTypePartialType.data);
      this.updateShowAnswers();
    },

    handleChangePartialType(value) {
      this.surveyTypePartialType.partialsType = this.partialsTypes.find(
        (el) => el.id === value,
      );
      this.updateShowAnswers();
    },
    handlerUpdateData(data) {
      this.surveyTypePartialType.data = data;
    },
    handlerChangeData(data) {
      Object.keys(this.surveyTypePartialType.data).forEach((key) => {
        if (data.index === parseInt(key, 0)) {
          this.surveyTypePartialType.data[key] = data.text;
        }
      });
    },
    updateShowAnswers() {
      if (this.surveyTypePartialType.partialsType) {
        this.showAnswers = this.surveyTypePartialType.partialsType.id === '4'
          || this.surveyTypePartialType.partialsType.id === '5';
      }
    },
    handleChangeName(content) {
      this.surveyTypePartialType.name = content;
    },
    handleChangeTitle(content) {
      this.surveyTypePartialType.title = content;
    },
    handleChangeDescription(content) {
      this.surveyTypePartialType.description = content;
    },
    handleChangeHelp(content) {
      this.surveyTypePartialType.help = content;
    },
    handleChangePlaceholder(content) {
      this.surveyTypePartialType.placeholder = content;
    },
    handleChangeIsRequired(content) {
      this.surveyTypePartialType.isRequired = content;
    },
    handleChangeData(content) {
      this.surveyTypePartialType.data = content;
    },

    uploadSurveyTypePartialType() {
      const newSurveyTypePartialType = {
        id: this.surveyTypePartialType.id,
        partialsTypes: this.surveyTypePartialType.partialsTypes,
        partialsType: this.surveyTypePartialType.partialsType,
        title: this.surveyTypePartialType.title,
        description: this.surveyTypePartialType.description,
        help: this.surveyTypePartialType.help,
        name: this.surveyTypePartialType.name,
        placeholder: this.surveyTypePartialType.placeholder,
        isRequired: this.surveyTypePartialType.isRequired ?? false,
        data: JSON.stringify({
          ...this.getJson(this.surveyTypePartialType.data),
        }),
        surveyTypeId: this.surveyType.id,
      };

      this.$emit('handleUploadSurveyTypePartialType', newSurveyTypePartialType);
    },
  },
  computed: {
    getSelectDefaultText() {
      return getSelectDefault('partial_type');
    },
    getPartialsType() {
      const newPartialsTypes = this.partialsTypes.map((partialsType) => {
        const newPartialsType = {
          value: partialsType.id,
          text: partialsType.name,
        };
        return newPartialsType;
      });

      newPartialsTypes.unshift(this.getSelectDefaultText);
      return newPartialsTypes;
    },
    isDisabled() {
      return !this.isEditable;
    },

    getTitle() {
      return this.isDisabled ? this.$i18n.t('lb_survey_types_partials_types') : this.$i18n.t('lb_add_survey_type_partial_type');
    },
  },
  components: {
    InputCollection,
    SelectGroup,
    Textarea,
    Checkbox,
    InputGroup,
    ModalAction,
  },
};
</script>
