<template>
  <SideBar
    class="SideForm__bar"
    :id="id"
    :isLoading="isLoading"
    @handleAccept="create"
    @onHiddenSidebar="handleCancel"
    showFooter
  >
    <slot></slot>
    <SurveyTypeForm
      id="createSurveyType"
      ref="createSurveyType"
      v-if="show"
      :title="$i18n.t('lb_new_survey_type')"
      @handleCancel="handleCancel"
      :isLoading="isLoading"
    />
  </SideBar>
</template>

<script>
import { mapGetters } from 'vuex';
import SurveyTypeForm from '@/components/organisms/SurveyType/SurveyTypeForm.vue';
import SideBar from '@/components/atoms/SideBar/SideBar.vue';

export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isLoading: false,
    };
  },
  mounted() {
    this.$bvModal.show('create-survey');
  },
  methods: {
    create() {
      const data = this.$refs.createSurveyType.getData();
      const newData = {
        id: data.id,
        name: data.name,
        language: this.user.defaultLanguage,
        templatePath: data.templatePath,
        createdBy: data.createdByData,
        surveyTypesPartialsTypes: data.surveyTypesPartialsTypes,
      };
      this.isLoading = true;
      this.$store.dispatch('surveyType/createSurveyType', newData)
        .then((res) => {
          const resData = data;
          resData.id = res.id;
          resData.language = res.language;
          this.$emit('addSurveyType', resData);

          data.surveyTypesPartialsTypes.map((element) => {
            const newElement = {
              ...element,
              partialsTypeId: element.partialsType.id,
              surveyTypeId: newData.id,
            };
            const response = this.$store.dispatch('surveyTypePartialsType/createSurveyTypePartialsType', newElement)
              .then((resp) => resp)
              .catch(() => {
                this.isLoading = false;
              });
            return response;
          });
          this.$refs.createSurveyType.resetSurveyTypeData();
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
        });
    },

    handleCancel() {
      this.$emit('handleCancel');
    },
  },
  computed: {
    ...mapGetters('user', {
      user: 'getCurrentUser',
    }),
  },
  components: {
    SideBar,
    SurveyTypeForm,
  },
};
</script>
