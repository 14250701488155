import { render, staticRenderFns } from "./SurveyTypeForm.vue?vue&type=template&id=6945903c&scoped=true&"
import script from "./SurveyTypeForm.vue?vue&type=script&lang=js&"
export * from "./SurveyTypeForm.vue?vue&type=script&lang=js&"
import style0 from "./SurveyTypeForm.vue?vue&type=style&index=0&id=6945903c&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6945903c",
  null
  
)

export default component.exports