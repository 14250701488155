<template>
  <ModalAction
    id="delete-survey-type"
    :okLabel="$i18n.t('btn_confirm')"
    :cancelLabel="$i18n.t('btn_cancel')"
    @handleOk="deleteSurveyType"
    isCentered
    :hideHeader="true"
    :isLoading="isLoading"
    fullSpinner
  >
    <div class="text-center">
      <p class="text__title">¿Estás seguro?</p>
      <div class="sp__v__1s5x"/>
      <p class="text__pr">
        {{ $i18n.t('lb_confirm_text') }}
      </p>
    </div>
  </ModalAction>
</template>

<script>
import ModalAction from '@/components/molecules/Modal/ModalAction.vue';

export default {
  data() {
    return {
      id: {
        type: String,
        default: null,
      },
      isLoading: false,
    };
  },
  methods: {
    setId(id) {
      this.id = id;
    },

    deleteSurveyType() {
      this.isLoading = true;

      this.$store.dispatch('surveyType/deleteSurveyType', this.id)
        .then(() => {
          this.$emit('deleteSurveyType', this.id);
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
  },
  components: {
    ModalAction,
  },
};
</script>
