<template>
  <SideBar
    class="SideForm__bar"
    :id="id"
    @handleAccept="handleUpdate"
    @onHiddenSidebar="handleCancel"
    showFooter
  >
    <slot></slot>
    <SurveyTypeForm
      id="updateSurveyTypeForm"
      ref="updateSurveyTypeForm"
      :title="$i18n.t('lb_update_survey_type')"
      @handleOk="handleUpdate"
      @handleCancel="handleCancel"
      :surveyType="getSurveyType"
      :isLoading="isLoading"
      :validationState="validationState"
      isEdit
    />
  </SideBar>
</template>

<script>
import SideBar from '@/components/atoms/SideBar/SideBar.vue';
import { getValidationState, isValidForm } from '@/helpers/ValidationHelper';
import SurveyTypeForm from '@/components/organisms/SurveyType/SurveyTypeForm.vue';

export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    surveyType: {
      type: Object,
      default: () => {},
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isLoading: false,
      validationState: {
        name: null,
      },
      isDisabled: false,
    };
  },
  methods: {
    handleUpdate() {
      const data = this.$refs.updateSurveyTypeForm.getData();
      const nameValidationState = getValidationState(
        data.name,
      );

      if (isValidForm([
        nameValidationState,
      ])) {
        this.update(data);
      } else {
        this.validationState.name = nameValidationState;
      }
    },

    update(data) {
      const surveyTypeData = {
        id: data.id,
        body: {
          name: data.name,
          language: this.surveyType.language,
        },
      };

      this.isLoading = true;

      this.$store.dispatch('surveyType/updateSurveyType', surveyTypeData)
        .then(() => {
          this.$emit('updateSurveyType', data);
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
        });
    },

    handleCancel() {
      this.$emit('handleCancel');
      this.resetValidations();
    },

    resetValidations() {
      this.validationState.name = null;
    },
  },
  computed: {
    getSurveyType() {
      return this.surveyType;
    },

    isHealthManager() {
      return this.$store.getters['user/isHealthManager'];
    },

    isClinic() {
      return this.$store.getters['user/isClinic'];
    },

    isDoctor() {
      return this.$store.getters['user/isDoctor'];
    },
    getUser() {
      return this.$store.getters['user/getCurrentUser'];
    },
  },
  components: {
    SurveyTypeForm,
    SideBar,
  },
};
</script>
